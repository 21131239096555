import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Link, Em, Box, Section } from "@quarkly/widgets";
import { MdLocationOn } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
const defaultProps = {
	"background": "--color-darkL1",
	"quarkly-title": "Footer-19",
	"padding": "2rem 0 2rem 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-color": "#232a44",
			"md-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "0px 0px 15px 0px",
			"sm-flex-direction": "column",
			"justify-content": "space-between",
			"align-items": "center",
			"grid-gap": "24px",
			"width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "24px",
			"color": "--light"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0 5px 0px",
			"hover-color": "#a78bfa",
			"children": "Zerdalilik, Değirmenönü Cd. No:8/B, 07100 Muratpaşa/Antalya, Türkiye"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"size": "24px",
			"color": "--light",
			"category": "fa",
			"icon": FaPhone
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"hover-color": "#a78bfa",
			"href": "tel:0669903888",
			"children": "+905326751637"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Icon {...override("icon")} />
					<Link {...override("link")} />
				</Box>
				<Box {...override("box3")}>
					<Icon {...override("icon1")} />
					<Link {...override("link1")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;